import { useQuery } from "@tanstack/react-query";
import { mains } from "../components/constants/routes";

const fetchAllTriviaQuestions = async () => {
  const user = await localStorage.getItem("logged_in");
  const parsed = JSON.parse(user);
  const main = await mains();

  const url = `${main.API_USER_ELEMENT_GAME_DATA}/trivia-question-list?limit=2000`; // Fetch all questions
  const options = {
    method: "GET",
    headers: {
      "api-key": main.API_KEY,
      Accept: "application/json",
      Authorization: "Bearer YOUR_TOKEN_HERE", // Replace with your actual token
    },
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error("Failed to fetch trivia questions");
  }
  const data = await response.json();

  // Filter trivia questions for the logged-in user
  const myTrivia = data.data.filter(
    (item) => item.data.author === parsed.user.username
  );

  return myTrivia;
};

const useMyTriviaList = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["allTriviaQuestions"], // Query key for caching
    queryFn: fetchAllTriviaQuestions,
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
    refetchOnWindowFocus: false, // Prevent refetch on window focus
  });

  return {
    myTriviaQuestions: data || [],
    isLoading,
    isError,
    error,
  };
};

export default useMyTriviaList;
