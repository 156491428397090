// store.js
import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "./navigation/navigationSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, navigationReducer);


const store = configureStore({
    reducer: {
        navigation: persistedReducer,
    },
});

export const persistor = persistStore(store);
export default store;
