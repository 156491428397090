import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTab: 'one', // Track the currently active tab
  lastTab: 'null',    // Store the last visited tab
  appID: ''
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.lastTab = state.currentTab; // Persist the current tab as the last tab
      state.currentTab = action.payload; // Update the current tab
    },
    setAppID: (state, action) => {
      state.appID = action.payload; // Persist the current tab as the last tab
    },
    resetApp: (state, action) => {
      state.currentTab = 'one';
      state.lastTab = 'null';
      state.appID = '' // Persist the current tab as the last tab
    }
  },
});

export const { setCurrentTab, setAppID, resetApp } = navigationSlice.actions;
export default navigationSlice.reducer;
