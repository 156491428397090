import { useState } from 'react';
import { message } from "antd";
import { mains } from '../components/constants/routes';
import { useNavigate } from "react-router-dom";


function formatCategoryName(categoryName) {
  // Replace spaces with hyphens
  let formattedName = categoryName.replace(/\s+/g, '-');
  
  // Convert to lowercase
  formattedName = formattedName.toLowerCase();
  
  // Append 'display_' to the beginning
  formattedName = 'display_' + formattedName;
  
  return formattedName;
}


function getCurrentDateTime() {
  const now = new Date();
  const month = now.getMonth() + 1; // getMonth() is zero-indexed
  const day = now.getDate();
  const year = now.getFullYear();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 24h to 12h format and handle midnight case
  return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
}




function parsefeudQuestionCache(feudQuestionCache) {
  let df = 300
  let newData = []
  feudQuestionCache.map((item, index) => {
    if (item.description != null) {
      let feudQueston = {

      }
      let temp = {
        "description": item.description,
        "answers": null,
        "answerPoints": feudQueston,
        "imageUrl": item.imageUrl,
      }
      item.answerPoints.map((item, index) => {
        feudQueston[item.key] = item.value || parseInt(item.value) === 0 ? parseInt(item.value) : parseInt(df)
      })
      
      newData.push(temp)
    }
  })
  return newData
}

function generateUniqueId(prefix = "trivia-questions") {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${prefix}-${s4()}-${s4()}-${s4()}-${s4()}`;
}
let Tagset = ["type_choice", 'type_quize', "type_feud", 'access_public', 'access_private', 'set_community'];

const useTriviaQuestionCreator = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [customTrivia, setCustomTrivia] = useState({});
  const navigate = useNavigate();
  /**
   lookup field need to be updated to reflex some changes 
  */


  const uploadLookupToQuestion = async (qustionToUpload) => {
    let main = await mains()

    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_ELEMENT_GAME_DATA, options);
      const data = await response.json();
      console.log("updated lookup field", data);
      
      if (data?.success) {
        messageApi.success("Done");
      } else {
        messageApi.info(`${data.message} `);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createQuestionWithData = async (title, selectedCategory, description, gameType, triviaQuestion, feudQuestionCache, triviaID, privecy, thumbnail, appID) => {
    console.log('thumbnail', thumbnail);
    
    let main = await mains()
    let user = await localStorage.getItem("logged_in");
    if (user == null) {
      navigate("/login");
    }
    let jsonUser = await JSON.parse(user);
    let auther = jsonUser?.user?.username;
    // Created Date Time
    let now = await getCurrentDateTime();
    // Firste Create a trivia id for the user
    let newGeneratedID = await generateUniqueId();

    // Then Build the specific Data for upload to update the list
    let listToUpdated = {
      name: title,
      author: auther,
      dataId: newGeneratedID,
      thumbnail: thumbnail,
      created: now,
      tags: [...selectedCategory, gameType, privecy,formatCategoryName(title), `user_${jsonUser?.user.id}`, 'set_community', appID],
    };
    if (description) {
      listToUpdated["description"] = description;
    }
    // // uppend it to the old trivia list
    let uploadableJson = {
      data_id: "trivia-question-list",
      data: listToUpdated,
      thumbnail: thumbnail,
      tags: [...selectedCategory, gameType, privecy, formatCategoryName(title), `user_${jsonUser?.user?.id}`, 'set_community', appID],
      element_id: newGeneratedID,
      sort_field_1: "000000000000"

    };
    triviaID.current = newGeneratedID;
    // upload the new json file to the server
    
    await uploadLookupToQuestion(uploadableJson);
    // Once the user data is set Lock the Button and move on
    // Now Get the questions and upload them to the question server
    let qustionToUpload = {
      data_id: triviaID.current,
      type: gameType,
      data: {
        questions: gameType == 'type_choice' || '' ? triviaQuestion : parsefeudQuestionCache(feudQuestionCache),
      },
      tags: uploadableJson.tags,
      element_id: "0",
      sort_field_1: "000000000000"

    };

    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_UPLOAD_QUESTION, options);
      const data = await response.json();
      if (data?.success) {
        // alert("Success")
        return 1
      } else {
        // alert("Error")
        return 0
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateQuestionWithData = async (data, triviaQuestionCache, elementID, selectedCategory, title, gameType, description, feudQuestionCache, privecy, thumbnail, appID ) => {
    let main = await mains()
    let user = await localStorage.getItem("logged_in");
    if (user == null) {
      navigate("/login");
    }
    let jsonUser = await JSON.parse(user);
    let auther = jsonUser?.user?.username;
    // // Created Date Time
    let now = await getCurrentDateTime();
    let tags = [];
    selectedCategory.map((item, index) => {
      if (!Tagset.includes(item)) {
        if (item.includes('display_')){
          // const result = item.replace(/_.*/, `_${title}`)
          let newResult = formatCategoryName(title)
          tags.push(newResult)
        } else{
          tags.push(item);
        }
      }
    });
    // console.log("Title is Here =======> ", title);
    let tagData = [...tags, gameType, privecy, `user_${jsonUser?.user?.id}`, 'set_community', ]
    if (!tagData.includes(appID)){
      tagData.push(appID)
    }
    let listToUpdated = await {
      name: title,
      thumbnail: thumbnail,
      author: auther,
      dataId: data,
      created: now,
      tags: tagData,
    };
    // console.log("Title is Here =======> ", listToUpdated);

    // // ---------------------------------------------------------------- would be ideal if we check for redundant titles
    // // Create the new json file to upload
    let uploadableJson = await {
      data_id: "trivia-question-list",
      data: listToUpdated,
      thumbnail: thumbnail,
      tags: tagData,
      element_id: data,
    };
    // console.log("Title is Here =======> ", uploadableJson);

    await uploadLookupToQuestion(uploadableJson);
    let qustionToUpload = {
      data_id: data,
      data: {
        questions: gameType == 'type_choice' || '' ? triviaQuestionCache : parsefeudQuestionCache(feudQuestionCache),
      },
    };

    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_UPLOAD_QUESTION, options);
      const data = await response.json();
      if (data?.success) {
        return 1
      } else {
        return 0
      }
    } catch (error) {
      console.error(error);
    }
  };
  return { createQuestionWithData, updateQuestionWithData };
};

export default useTriviaQuestionCreator;
