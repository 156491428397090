import { Tooltip, Button, Radio } from "antd";
import Custominput from "../GPTInput";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export const generationButtonCollection = (prompt, gameType, isLoading, first, isLoadingFeud, handleGenerateClick, handleGenerateFeudClick) => {
    return (
        <div>
            {prompt ? (
                <Tooltip
                    title={"tap generate to use AI"}
                    trigger="hover"
                    placement="right"
                    color="#2E313B"
                    defaultOpen
                >
                    {gameType === "type_choice" ? (
                        <Button
                            loading={isLoading}
                            onClick={() => handleGenerateClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    ) : (
                        <Button
                            loading={isLoadingFeud}
                            onClick={() => handleGenerateClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    )}
                </Tooltip>
            ) : (
                <Tooltip
                    title="Create Your Custom Question"
                    trigger="hover"
                    placement="right"
                    color="#2E313B"
                    defaultOpen
                >
                    {gameType === "type_choice" ? (
                        <Button
                            loading={isLoading}
                            disabled
                            onClick={() => handleGenerateFeudClick()}
                            className="flex flex-row  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    ) : (
                        <Button
                            loading={isLoadingFeud}
                            onClick={() => handleGenerateFeudClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    )}
                </Tooltip>
            )}
        </div>
    );
};

export const renderCustomInput = (gameType, prompt, handleInputChange, promptFeud, handleInputChangeFeud) => {
    if (gameType === "type_choice") {
        return (
            <Custominput
                prompt={prompt}
                handleInputChange={handleInputChange}
                gameType="trivia"
            />
        );
    } else if (gameType === "type_feud") {
        return (
            <Custominput
                prompt={promptFeud}
                handleInputChange={handleInputChangeFeud}
                gameType="feud"
            />
        );
    } else if (gameType === "type_quize") {
        return (
            <Custominput
                prompt={promptFeud}
                handleInputChange={handleInputChangeFeud}
                gameType="quize"
            />
        ); // Or handle the 'type_quize' case appropriately
    }
    return null;
};

export const renderGenerateAndAdd = (gameType, endRef, addtotriviacachecustom, isLoading, handleGenerateClick, isLoadingFeud, addtoFeudcachecustomFeud, handleGenerateFeudClick) => {
    return (
        <>
            {gameType === "type_choice" ? (
                <div
                    ref={endRef}
                    className="flex w-[100%] flex-row justify-between items-center"
                >
                    <button
                        onClick={() => addtotriviacachecustom()}
                        className="px-3 my-4 py-2 text-sm uppercase ml-5  rounded-3xl shadow-sm  bg-[#414550] cursor-pointer  text-[#fff]"
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" size='sm' />
                    </button>
                    <Button
                        loading={isLoading}
                        onClick={() => handleGenerateClick()}
                        className="mr-[40%] text-white cursor-pointer px-5 py-1 text-xs rounded-md border-[1px] !bg-[transparent] hover:!text-[white] hover:!border-[#414550] border-[#414550] uppercase"
                    >
                        Generate more
                    </Button>
                </div>
            ) : (
                <div
                    ref={endRef}
                    className="flex w-[100%] flex-row justify-between items-center"
                >
                    <button
                        onClick={() => addtoFeudcachecustomFeud()}
                        className="px-3 my-4 py-2 text-sm uppercase ml-5  rounded-3xl shadow-sm  bg-[#414550] cursor-pointer  text-[#fff]"
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" size='sm' />
                    </button>
                    <Button
                        loading={isLoadingFeud}
                        onClick={() => handleGenerateFeudClick()}
                        className="mr-[40%] text-white cursor-pointer px-5 py-1 text-xs rounded-md border-[1px] !bg-[transparent] hover:!text-[white] hover:!border-[#414550] border-[#414550] uppercase"
                    >
                        Generate more
                    </Button>
                </div>
            )}</>
    )
}

export const renderHandleNextClick = (gameType, triviaQuestionCache, handleUploadToServer, feudQuestionCache, handleUploadToServerFeud) => {
    return <>
        {gameType === "type_choice" ? (
            <div
                className={`flex flex-row items-center justify-center bg-[#2B2F38]  w-[100%] ${triviaQuestionCache ? "py-5" : "py-0"
                    } `}
            >
                <div className="mr-10 w-[100%]">
                    {triviaQuestionCache ? (
                        <div className="flex flex-row justify-between w-[100%]">
                            <Button className="px-0 hover:!text-white ml-8  rounded-sm !bg-transparent hover:!outline-none !border-0 text-white !outline-none"></Button>
                            <Button
                                onClick={handleUploadToServer}
                                className="px-5 hover:!text-white  ml-5 rounded-sm !bg-[#414550] hover:!outline-none !border-0 text-white !outline-none"
                            >
                                Next
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        ) : (
            <div
                className={`flex flex-row items-center justify-center bg-[#2B2F38]  w-[100%] ${feudQuestionCache ? "py-5" : "py-0"
                    } `}
            >
                <div className="mr-10 w-[100%]">
                    {feudQuestionCache ? (
                        <div className="flex flex-row justify-between w-[100%]">
                            <Button className="px-0 hover:!text-white ml-8  rounded-sm !bg-transparent hover:!outline-none !border-0 text-white !outline-none"></Button>
                            <Button
                                onClick={handleUploadToServerFeud}
                                className="px-5 hover:!text-white  ml-5 rounded-sm !bg-[#414550] hover:!outline-none !border-0 text-white !outline-none"
                            >
                                Next
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        )}</>
}

export const renderRadioButtonCollection = (gameType, gameTypeChange) => {
    return (
    <Radio.Group
        onChange={(e) => gameTypeChange(e)}
        className="bg-[] w-[99%]"
        name="radiogroup"
        defaultValue={"type_feud"}
        value={gameType}
    >
        {/* <Radio className="text-white" value={"type_choice"}>
            Trivia
        </Radio> */}
        <Radio className="text-white" value={"type_feud"}>
            Feud
        </Radio>
        <Radio className="text-white" value={"type_quize"}>
            Quiz
        </Radio>
    </Radio.Group>
    )
}


export const renderPrivecy = (privecyType, privecyTypeChange) => {
    return (
    <Radio.Group
        onChange={(e) => privecyTypeChange(e)}
        className="w-[99%] "
        name="radiogroup"
        defaultValue={"access_public"}
        value={privecyType}
    >
        <Radio className="text-white" value={"access_public"}>
            Public
        </Radio>
        <Radio className="text-white" value={"access_private"}>
            Private
        </Radio>
    </Radio.Group>
    )
}