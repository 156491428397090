import React from "react";
import useMyTriviaList from "../customHooks/useMyTrivaList";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Spin, message, Badge, Pagination, Modal } from "antd";
import { mains } from "./constants/routes";
import event from "../customHooks/useGoogleAnalyticsTrigger";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "../reduxStore/navigation/navigationSlice";

const { confirm } = Modal;

export default function MyTrivia({ onChange }) {
  const [messageApi, contextHolder] = message.useMessage();
  const { myTriviaQuestions, isLoading, isError, error } = useMyTriviaList();
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.navigation.currentTab);

  const handleTabChange = (tabName) => {
    dispatch(setCurrentTab(tabName));
  };



  const deleteUploadLookupToQuestion = async (qustionToUpload) => {
    let main = await mains();
    event({
      action: "Delete",
      category: "Delete",
      label: `Delete`,
      value: qustionToUpload.element_id,
    });
    const url =
      main.API_USER_ELEMENT_GAME_DATA +
      `/trivia-question-list/elements/${qustionToUpload.element_id}`;

    const options = {
      method: "DELETE",
      headers: {
        "api-key": main.API_KEY,
        Accept: "application/json",
        Authorization: "Bearer fMnmHgaqcvs524DKhYGxuCbZWw7NXByLR6Fzt3TAEQdr9pkjPJ",
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.success) {
        window.location.reload();
        onChange("");
      } else {
        messageApi.info(`${data.message} `);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure you want to delete this trivia?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteUploadLookupToQuestion(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showUpdateConfirm = (item) => {
    confirm({
      title: "Are you sure you want to edit this trivia?",
      icon: <ExclamationCircleOutlined />,
      content: "Ensure you have reviewed the changes before proceeding.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        handleTabChange('one');
        onChange(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div className="flex flex-col mx-10 my-5 items-center min-h-[80vh]">
      <h1 className="text-white text-xl font-bold uppercase ">My Trivia</h1>
      {isLoading ? (
        <div className="flex flex-col mt-20 w-[50vw]">
          <Spin size={50} />
        </div>
      ) : (
        <div className="flex flex-col w-[50vw]">
          {myTriviaQuestions.map((item, index) => (
            <Badge.Ribbon
              placement="start"
              key={index}
              text={
                item?.data?.tags ? (
                  <div>
                    {item?.data.tags.map((item, index) => {
                      if (["type_choice", "type_feud", "type_quize"].includes(item)) {
                        return <div key={index} className="text-sm">{item}</div>;
                      }
                    })}
                  </div>
                ) : null
              }
              color="#3f5d3f"
            >
              <div className="flex flex-row  justify-between items-center py-1 text-white bg-[#414550] text-xl px-2 mt-6 border-b-2 rounded-md">
                <div>
                  <h1 className="mr-2 text-sm uppercase">{item.data.name}</h1>
                  <h1 className="text-xs">{item.data.dataId}</h1>
                </div>
                <div className="w-20  right-[45vw]">
                  <div className="flex flex-row justify-between w-[100%] h-[100%]">
                    <a
                      onClick={() => showUpdateConfirm(item)}
                      className="cursor-pointer hover:bg-[black] px-2"
                    >
                      <EditOutlined />
                    </a>
                    <a
                      onClick={() => showDeleteConfirm(item)}
                      className="cursor-pointer hover:bg-[black] px-2"
                    >
                      <DeleteOutlined />
                    </a>
                  </div>
                </div>
              </div>
              <br />
            </Badge.Ribbon>
          ))}
        </div>
      )}
    </div>
  );
}
