import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route components
import './App.css';
import Header from './components/Header/Header';
import Main from './components/Main'
import './components/PromptInput/PromptInput.css'
import Auth from './components/Auth'
import MyTrivia from './components/MyTrivia'
import store from './reduxStore/store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./reduxStore/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";




const queryClient = new QueryClient();

function App() {
  const [prevScrollPos, setPrevScrollPos] = React.useState(window.pageYOffset);
  const [user, setUser] = useState(null);
  const authRef = React.useRef(null);
  const [loading, setIsLoading] = useState(true);


  const authenticated = async () => {
    let users = await localStorage.getItem("logged_in");
    let parsed = await JSON.parse(users);
    authRef.current = parsed;
    await setUser(parsed);
    await setIsLoading(false)
  };

  useEffect(() => {

    authenticated()
  }, [user?.identity_token])




  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        document.getElementById("header").style.top = "0"; // Show navbar when scrolling up
      } else {
        document.getElementById("header").style.top = "-13vh"; // Hide navbar when scrolling down
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);


  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <Header />
            <div className='bg-with-icon'>
              {loading ?
                <div>loading</div>
                :
                <Routes>
                  <Route path="/login" element={<Auth />} />
                  <Route path='/' element={<Main />} />
                  <Route path="/mytrivia" element={<MyTrivia />} />
                </Routes>}
            </div>
          </div>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
