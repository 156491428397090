import React from "react";

export default function index({ prompt="", handleInputChange, gameType }) {
  return (
    <input
      type="text"
      className=" !py-2 !shadow-md border-0 bg-[#121315] w-[75%] text-center rounded-sm outline-none text-white"
      placeholder={`Type the ${gameType} you want to create here`}
      value={prompt}
      onChange={handleInputChange}
    />
  );
}
